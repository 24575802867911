<template>
  <div>
    <section class="speciality-area">
      <div class="container">
        <div v-if="viewPaper" style="max-width: 1000px; margin: auto;">
          <div v-for="mainPageObj in mainPageObjList" :key="mainPageObj.id" class="main-paper-header p-2 ">
            <div class="article-header justify-content-between row">
              <h4 class="col-8 col-sm-9 col-md-10">{{ mainPageObj.title }}</h4>
              <div class="col-4 col-sm-3 col-md-2">

                <button type="submit" @click="openPaperDetails(mainPageObj.url_title)" class="viewArticlebtn p-2 float-right">
                  View Article
                </button>
              </div>
            </div>
            <div class="col">
              <!-- <img :src="mainPageObj.image" alt=""> -->
              <h6>{{ mainPageObj.title }}</h6>
              <p><b>Category By : </b>{{ mainPageObj.category }}</p>
              <p><b>Date : </b>{{ mainPageObj.created_at.split("T")[0] }}</p>
            </div>
          </div>

        </div>
        <div v-if="!viewPaper && paperpage" class="container" style="max-width: 1000px; margin: auto;">
          <div v-if="this.paperDetails == undefined">
            loading...
          </div>
          <div v-else>
            <h1>{{ this.paperDetails.title }}</h1>
            <h6 class="form-control-sm" style="color: gray;"><strong>Category</strong> : <span>{{
              this.paperDetails.category }}</span></h6>
            <h6 class="form-control-sm" style="color: gray;"><strong>Date</strong> : <span>{{
              this.paperDetails.created_at.split("T")[0] }}</span></h6>
            <div
              v-if="this.paperDetails.image !== 'https://ijrtmr.com/blogImage/' && this.paperDetails.image !== undefined | null"
              class="d-flex align-content-center justify-content-center py-5">

              <img class="mx-auto block" style="max-width: 25rem;" :src="this.paperDetails.image" alt="image">
            </div>
            <article class="" v-html="artical()">

            </article>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.paperDetails?.meta_title ||'Blogs | International Journal | IJRTMR',
      meta: [
        {
          name: 'description',
          // content: this.paperPage?String(this.paperDetails.paper_abstract).split(" ").slice(0,70).join(" ") :'Access IJRTMR archives to explore past issues. Browse research articles in Engineering, Technology, and Applied Sciences from previous editions.'
          content: this.paperDetails.meta_description !== undefined ? `${String(this.paperDetails.meta_description).split(".")[0]}.` : 'Access IJRTMR archives to explore past issues. Browse research articles in Engineering, Technology, and Applied Sciences from previous editions.'
        },
        {
          name: 'keywords',
          content: this.paperDetails.meta_description !== undefined ? `${String(this.paperDetails.tags)}` : 'International Journal, IJRTMR, Engineering, Technology, Applied Sciences, Research Articles, Archives'
        },
        {
          property: 'og:title',  // Open Graph title for social sharing
          content: document.title
        },
        {
          property: 'og:url',
          content: window.location.href
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: window.location.href,
        },
      ],
    };
  },
  data() {
    return {
      viewPaper: true,
      paperpage: false,
      paperDetails: {},
      mainPageObjList: [],
      title: "",
    };
  },

  methods: {
    openPaperDetails(id) {
      this.$router.push({ path: `blogs/${id}`});
      // console.log(year, volume, issue, name);
      this.viewPaper = false;
      this.paperpage = true;
    },
    downloadPdf(paperURL) {
      window.open(paperURL, "_blank");
    },
    artical() {
      this.paperDetails.description = this.paperDetails.description.replace("\n", "<br>").replace("&bullet;", "<br>&bullet;").replace("&bull;", "<br>&bull;").replace("_", " ");
      return this.paperDetails.description;
    },
    async getBlogData() {
      try {
        const res = await this.api.postData("blogFetch");
        // console.log("list data", await res.data.blogList);
        this.mainPageObjList = res.data.blogList;


      } catch (err) {
        console.error(err);
      }
    },
    async getBlogDetails(paperid) {
      try {
        const res = await this.api.postDataModulePagination("blogDetails", { url_title: paperid });
        // console.log("conferencedetails", await res.data.blogDetails);
        this.paperDetails = res.data.blogDetails;
        // this.paperDetails.description = this.paperDetails.description.replace("\n", "<br>")

      } catch (err) {
        console.error(err);
        this.paperDetails = {};
      }
    },
  },
  watch: {
    $route:{
      immediate:true,
      handler(newRoute) {
        if (newRoute.params.slug) {
          this.getBlogDetails(newRoute.params.slug);
          this.paperpage = true;
          this.viewPaper = false;
        } else {
          this.getBlogData();
          this.paperpage = false;
          this.viewPaper = true;
        }
      },
    }
  },
  mounted() {
    // console.log("running on con");
    if (this.$route.params.slug) {
      this.getBlogDetails(this.$route.params.slug);
      this.paperpage = true;
      this.viewPaper = false;
    }
    else {
      this.getBlogData();
      this.paperpage = false;
      this.viewPaper = true;
    }

    // this.getBlogDetails(1);
  },
};

</script>

<style scoped>
.download-pdf {
  border: 1px solid #22bbf0;
  color: #22bbf0;
  background: transparent;
  font-weight: 600;
  border-radius: 5px;
}

.download-pdf:hover {
  color: #fff;
  background-color: #22bbf0;
}

.header-title {
  border-radius: 5px;
  text-align: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.paper-container {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
}

.sub-header-title {
  display: flex;
  justify-content: space-between;
}

.viewArticlebtn {
  border: 1px solid #22bbf0;
  color: #22bbf0;
  background: transparent;
  font-weight: 600;
  border-radius: 5px;
}

.viewArticlebtn:hover {
  color: #fff;
  background-color: #22bbf0;
}

.article-header {
  display: flex;
  justify-content: space-between;
}

.main-paper-header {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  margin-bottom: 1rem;

}

.recentPosts {
  border-bottom: 1px dashed black;
}

.blogTitle {
  border-bottom: 1px dashed black;
}
</style>