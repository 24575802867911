<template>
  <div>
    <section class="">
      <div class="container">
        <div class="row justify-content-between">
          <div class="row col-12 align-items-center py-5">
            <div class="col-12 col-md-8">
              <h3>Aim</h3>
              <p>
                The Journal aims to be an Academic Open Access, Double-Blind
                Peer-Reviewed, Interdisciplinary, Monthly, and Completely
                Refereed Publication Focusing on Engineering and Technology
                Theories, Methodologies, And Applications. It provides original
                study articles and technical notes that have been peer-reviewed
                in all engineering and technology fields. All submitted articles
                must report original, previously unpublished research results,
                whether experimental or theoretical, and will be peer-reviewed
                twice. Papers submitted to the Journal must meet these criteria
                and must not be considered for international journal publication
                elsewhere. Manuscripts must adhere to the Journal's style and
                are subject to review and editing. To provide a platform for
                publishing results and research with a vital empirical
                component, to bridge the significant gap between research and
                practice by encouraging the publication of original, novel,
                industry-relevant research, and to solicit original and
                unpublished research papers based on theoretical or experimental
                works.
              </p>
            </div>
            <div class="col-12 col-md-4">
              <img
                class=""
                src="@/assets/Images/Aim.png"
                style="width: 100%; height: auto;"
                alt="image"
              />
            </div>
          </div>
          <div class="row">
            <h3>The Aim of The Journal Is To: </h3>
            <ul>
              <li>
                <p>
                  Disseminate Original, Scientific, Theoretical, Or Applied
                  Research in the Engineering and Allied Fields.
                </p>
              </li>
              <li>
                <p>
                  Create A Bridge for a Significant Gap Between Research and
                  Practice by Promoting the Publication of Original, Novel,
                  Industry-Relevant Research.
                </p>
              </li>
              <li>
                <p>
                  Aqueduct The Significant Gap Between Research and Practice by
                  Promoting the Publication of Original, Novel,
                  Industry-Relevant Research.
                </p>
              </li>
              <li>
                <p>
                  Seek Original and Unpublished International Journal Research
                  Papers Based on Theoretical or Experimental Works for The
                  Publication Globally.
                </p>
              </li>
              <li>
                <p>
                  Solicit Original and Unpublished Research Papers, Based on
                  Theoretical or Experimental Works.
                </p>
              </li>
              <li>
                <p>
                  Publish Original, Theoretical, And Practical Advances in
                  Mechanical Engineering, Civil Engineering, Computer Science &
                  Engineering, Textile Engineering, Information Technology,
                  Electrical and Electronics Engineering, Electronics and
                  Telecommunication, And All Interdisciplinary Streams of
                  Engineering Sciences.
                </p>
              </li>
              <li>
                <p>
                  Impart A Platform for Publishing Results and Research with A
                  Strong Empirical Component.
                </p>
              </li>
              <li>
                <p>
                  Dispense A Platform for Publishing Results and Research With A
                  Strong Empirical Component.
                </p>
              </li>
            </ul>
          </div>
          <div class="row col-12">
            <div class="col-12 col-md-4 ms-1">
              <img
                src="@/assets/Images/Scope.png"
                style="width: 100%; height: 100%;"
                alt="image"
                class="image"
              />
            </div>
            <div class="col-12 col-md-8 scopeContent">
              <h3>Scope</h3>
              <p class="">
                The International Journal of Recent Trends in Interdisciplinary
                Research (IJRTMR) Is A Bi-Monthly Peer-Reviewed, Refereed,
                Indexed International Research Magazine, So There Is a Broad
                Range of Publication Opportunities. IJRTMR Is a Platform for All
                Scholars to Post Original Authoritative Research Papers, Essays,
                Research Projects, Reviews, Mini-Reviews, Case Studies,
                Synopsis, And Short International Journal Research Messages In A
                Variety Of Topics. Authors are encouraged to submit entire
                unpublished and original works that are not currently being
                considered by other publications.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h3 {
  padding-bottom: 20px;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
}
h5 {
  font-family: "Rubik", sans-serif;
  padding-bottom: 10px;
  font-size: 18px;
}
p {
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  color: rgb(100, 117, 137);
  text-align: justify;
  text-justify: none;
}
.missionList {
  margin-left: -20px;
}
.missionContent {
  padding-bottom: 20px;
}
.scopeContent {
  margin-top: 30px;
}
</style>