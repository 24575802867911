<template>
  <div>
    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-9 variant="info"
            >Refund Cancellation Policies
            <b-icon-arrow-down></b-icon-arrow-down>
          </b-button>
        </b-card-header>
        <b-collapse
          id="accordion-9"
          visible
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <b-card-text>
              <div>
                <p>
                  It is a scholarly communication general concept that the
                  editor/guest editor of a learned journal/series is exclusively
                  and independently accountable for deciding which papers
                  submitted to the International Journal Research Paper will be
                  published. In making this choice, the editor/guest editor is
                  led by the editorial board's policies and limited by current
                  legal requirements regarding libel, copyright violation, and
                  plagiarism. As a result of this concept, the academic archive
                  is essential as a permanent, historical document of scholarly
                  interactions. Articles already released must be kept as
                  accurate and unaltered as feasible. However, there are times
                  when a piece must be retracted or deleted after it has been
                  released. Such acts should not be done lightly and should only
                  be carried out in exceptional situations, such as:
                </p>
                <h5>Article Withdrawal : </h5>
                <p>
                  Only used for Articles in Press that represent early versions
                  of articles and sometimes contain errors or may have been
                  accidentally submitted twice. Occasionally, but less
                  frequently, the articles may represent infringements of
                  professional, ethical codes, such as multiple submissions,
                  bogus claims of authorship, plagiarism, fraudulent use of
                  data, or the like.
                </p>
                <h5>Article Retraction: </h5>
                <p>
                  Professional and ethical standards infractions include
                  numerous entries, false claims of authorship, plagiarism,
                  fraudulent use of data, and the like. Retractions are
                  occasionally used to rectify mistakes in submission or
                  International Journal publishing.
                </p>
                <h5>Article Removal: </h5>
                <p>
                  Legal limitations upon the international journal publisher,
                  copyright holder or author(s).
                </p>
                <h5>Article Replacement: </h5>
                <p>
                  Identification of false or inaccurate data that, if acted
                  upon, would pose a severe health risk.
                </p>
                <h5>Article withdrawal Policy: </h5>
                <p>
                  International Journal of Recent Trends in Multidisciplinary
                  Research (IJRTMR) is (Online), Bi-monthly international
                  journal for publishing new ideas founded by academicians,
                  educationists, engineers, and corporate people. The research
                  results and fundamental advancement are all aspects of
                  Innovative Research in Engineering & Technology, various
                  engineering disciplines, etc. IJRTMR is a scholarly open
                  access, Double Blind Peer Review Journal which helps academic
                  persons and the student community. IJRTMR provides the
                  academic community and industry for submitting original
                  research and applications related to Various Engineering
                  disciplines and Advanced Technologies. IJRTMR recognizes the
                  importance of the integrity and completeness of the scholarly
                  record to researchers and librarians and attaches the highest
                  importance to maintaining trust in the authority of its
                  electronic archive.
                </p>
                <h5>IJRTMR Policy</h5>
                <p>
                  It is a general principle of scholarly communication that the
                  editor/guest editor of a learned journal/series is solely and
                  independently responsible for deciding which articles
                  submitted to the journal shall be published. In making this
                  decision, the editor/guest editor is guided by the policies of
                  the journal’s editorial board and constrained by such legal
                  requirements in force regarding libel, copyright infringement,
                  and plagiarism. An outcome of this principle is the importance
                  of the scholarly archive as a permanent, historical record of
                  the transactions of scholarship. Articles that have been
                  published shall remain extant, exact, and unaltered as far as
                  possible. However, occasionally, circumstances may arise where
                  an article is published that must later be retracted or
                  removed. Such actions must not be undertaken lightly and can
                  only occur under exceptional circumstances.
                </p>
                <p>
                  This policy was created to handle these issues while
                  considering current best practices in the scholarly and
                  library groups. We will revisit this problem as standards
                  evolve and change, and we encourage feedback from the
                  scholarly and library groups. These problems necessitate
                  international standards, and we intend to actively urge
                  various information organizations to create international
                  standards and best practices that the international journal
                  publication and information sectors can use.
                </p>
                <h5>Article withdrawal: </h5>
                <p>
                  Only used for Articles in Press that represent early versions
                  of articles and sometimes contain errors or may have been
                  accidentally submitted twice. Occasionally, but less
                  frequently, the articles may represent infringements of
                  professional, ethical codes, such as multiple submissions,
                  bogus claims of authorship, plagiarism, fraudulent use of
                  data, or the like. Articles in Press (articles that have been
                  accepted for publication but which have not been formally
                  published and will not yet have the complete volume/issue/page
                  information) that include errors or are discovered to be
                  accidental duplicates of other published article(s) or are
                  determined to violate our international journal publishing
                  ethics guidelines in the view of the editors (such as multiple
                  submission, bogus claims of authorship, plagiarism, fraudulent
                  use of data or the like), maybe “Withdrawn” from IJRTMR.
                  Withdrawn means that the article content (HTML and PDF) is
                  removed and replaced with an HTML page and PDF stating that
                  the article has been withdrawn according to the IJRTMR
                  (International Journal of Recent Trends in Multidisciplinary
                  Research) Policy on Article in Press Withdrawal with a link to
                  the current policy document.
                </p>
                <h5>Article retraction : </h5>
                <p>
                  Infringement of professional, ethical standards, such as
                  numerous entries, false authorship claims, plagiarism,
                  fraudulent data use, or similar offenses. On rare occasions, a
                  retraction will be used to fix submittal or publish mistakes.
                  It has long been a rare occurrence in the academic world for a
                  paper to be retracted by its authors or the editor on the
                  advice of other scholars. Several scholarly and library
                  organizations have created standards for handling retractions,
                  and IJRTMR has embraced this best practice for paper
                  retractions.
                </p>
                <p>
                  A retraction notice titled "Retraction: [article title],"
                  signed by the authors and the editor, is released in the
                  paginated section of the next journal issue and mentioned in
                  the contents list.
                </p>
                <p>
                  In the electronic version, a link is made to the original
                  article.
                </p>
                <p>
                  The online article is preceded by a screen containing the
                  retraction note. It is to this screen that the link resolves;
                  the reader can then proceed to the article itself.
                </p>
                <p>
                  The original article is kept in its entirety, except for a
                  watermark on each page showing that it has been "retracted."
                  The HTML form of the text is no longer available.
                </p>
                <h5>Article removal: legal limitations: </h5>
                <p>
                  In a minimal number of cases, removing an article from the
                  online database may be necessary. This will only occur where
                  the article is defamatory or infringes others’ legal rights,
                  or where the article is, or we have good reason to expect it
                  will be, the subject of a court order, or where the article,
                  if acted upon, might pose a severe health risk. While the
                  metadata (Title and Authors) will be retained, the text will
                  be replaced with a screen indicating the article has been
                  removed for legal reasons.
                </p>
                <h5>Article replacement: </h5>
                <p>
                  In cases where the article, if acted upon, might pose a severe
                  health risk, the original article's authors may wish to
                  retract the flawed original and replace it with a corrected
                  version. In these circumstances, the procedures for retraction
                  will be followed with the difference that the database
                  retraction notice will publish a link to the corrected
                  re-published article and a history of the document.
                </p>
              </div>
              <!-- <div>
                <p>
                  <b
                    >1) There are no charges if the article does not reach the
                    reviewing stage/not accepted.
                  </b>
                </p>
                <p>
                  <b
                    >2) No Refund will be issued if Author wish to cancel the
                    Publication with us after completing payment.
                  </b>
                </p>
                <p>
                  <b
                    >3) There are no refunds or cancellation of Debts, if the
                    article is removed due to copyright infringement Claims.
                  </b>
                </p>
              </div>
              <div>
                p
                <p>
                  After Publish Issue/ Indexing author can’t make the correction
                  in the article. After publishing Issue/indexing, if the author
                  wants to correction in the article he/she have to pay extra at
                  per changes in the article
                </p>
              </div> -->
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.btn-info {
  background-color: #22bbf0;
}
.card {
  border: none;
}
</style>